<template>
  <v-container>
    <div class="d-flex justify-space-between my-5">
      <div class="v-text-field text-h4">Gutscheinverwaltung</div>
    </div>
    <v-card>
      <v-card-title>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Neue Gutscheine
            </v-btn>
          </template>
          <!-- <create-user v-on:newuser="newVoucherCreated"></create-user> -->
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="vouchers"
        :items-per-page="15"
        :search="search"
        :loading="loading"
        sort-by="id"
        :sort-desc="true"
        class="elevation-1"
      >
        <template v-slot:item.usedDate="{ item }">
          {{ getDisplayDate(item.usedDate) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="deleteVoucher(item)" small color="red">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="headline"><div>Möchten Sie diesen Gutschein löschen?</div></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteVoucherConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import dayjs from "dayjs";

export default {
  data() {
    return {
      search: "",
      loading: false,
      errored: false,
      dialog: false,
      dialogDelete: false,
      snackbar: false,
      snackbarText: "",
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Nummer", value: "number" },
        { text: "Eingelöst am", value: "usedDate" },
        { text: "Eingelöst von", value: "owner.fullName" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      vouchers: [],
      voucherIndex: 0,
    };
  },

  mounted() {
    this.loading = true;
    this.$http({
      method: "get",
      url: "/voucher",
      params: {
        limit: 10000,
      },
    })
      .then((response) => {
        this.vouchers = response.data;
        this.loading = false;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },
  methods: {
    getDisplayDate(timestamp) {
      return dayjs(timestamp).format("DD.MM.YYYY");
    },

    deleteVoucher(item) {
      this.voucherIndex = this.vouchers.indexOf(item);
      this.voucherDelete = item;
      this.dialogDelete = true;
    },

    deleteVoucherConfirm() {
      this.$http({
        method: "delete",
        url: "/voucher/" + this.voucherDelete.id,
      })
        .then(() => {
          this.snackbarText = "Gutschein erfolgreich gelöscht";
          this.snackbar = true;
          this.vouchers.splice(this.voucherIndex, 1);
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });
      this.dialogDelete = false;
    },
  },
};
</script>
